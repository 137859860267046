@import "../../variables.module.scss";

.toDo {
  height: 100%;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .toDo {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: row;

    .complete {
      height: 40%;
      text-align: center;
    }
  }
}
